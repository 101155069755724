import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="container">
      <header className="row">🚧 careful, under construction.</header>
      <div className="row">
        <h1>hi, i'm adrian busse.</h1>
      </div>
      <footer className="row">
        <a
          className="button"
          target="_blank"
          href="https://www.linkedin.com/in/adrian-busse/"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        <a className="button" href="mailto:hello@adrianmxb.com">
          Contact
        </a>
        <a
          className="button"
          target="_blank"
          href="https://github.com/adrianmxb"
          rel="noreferrer"
        >
          GitHub
        </a>
      </footer>
    </div>
  );
}

export default App;
